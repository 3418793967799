import React from 'react'
import styles from './UsesWithFrame.module.scss'
import { Typography } from '@mui/material'
import { KeyboardBackspaceOutlined } from '@mui/icons-material'
import frameImage from '../../public/images/index/index-qr-frame2.png'
import QRCode from 'react-qr-code'
import Image from 'next/image'

const usesData = [
  {
    title: `Property Rental`,
    description: `Enhance your vacation rental guests' experience with MyTags. Create custom Tags for each property and provide guests with important information such as check-in instructions, house rules, and recommendations for local attractions and restaurants.`,
    tagUrl: `https://mytags.info/t/mytags-examples/18/house-rules-example-with-rich-content`,
  },
  {
    title: `Events & Exhibits`,
    description: 'Use MyTags to provide additional information to your visitors. Create Tags for artworks containing detailed information or general Tags that guests can scan around the venue to get all the information they need to have a great experience at your event.',
    tagUrl: `https://www.mytags.info/t/mytags-examples/46/exhibit-example`,
  },
  {
    title: `Music Events`,
    description: 'Create Tags, print their codes and put them in the area surrounding the event to help visitors get to the venue, provide information such as maps, beverage/food prices, POI information, acts schedule and more!',
    tagUrl: `https://www.mytags.info/t/mytags-examples/43/primavera-sound-2023-barcelona`,
  },
  {
    title: `Public Venues & Spaces`,
    description: 'Provide information that would be either necessary or interesting to visitors. Describe the history of an old Town square, enrich it with videos and photos, point them to the next point of interest.',
    tagUrl: `https://www.mytags.info/t/mytags-examples/45/the-grounded-sun`,
  },
]

export default function UsesWithFrame() {

  const [selected, setSelected] = React.useState(0);

  const accordionItems = usesData.map((item, index) => { return (
    <li
      className={styles.accordionItem}
      key={`usesAccordionItem${index}`}
      onClick={() => setSelected(index)}
    >
      <div className={styles.itemTitle}>{item.title} {(selected === index) ? '' : <span>(click to see)</span>}</div>
      <div
        className={styles.itemContent}
        style={{
          maxHeight: (selected === index) ? 1000 : 0,
          paddingBottom: (selected === index) ? 10 : 0,
        }}
      >
        <p>{item.description}</p>
        <div className={styles.itemCta}>
          <KeyboardBackspaceOutlined color='primary' sx={{ mr: 1 }} />
          <span>Scan the example Tag using your phone (or <a href={item.tagUrl} target='_blank'>click here</a>)</span>
        </div>
      </div>
    </li>
  )})

  return (
    <section className={`${styles.indexUsages} ${styles.container}`}>
      <div className={styles.qrFrameContainer}>
        <Image
          src={frameImage}
          alt='Scan the QR code to see the Tag!'
          layout='responsive'
          sizes="(min-width: 75em) 33vw,
              (min-width: 48em) 50vw,
              100vw"
        />
        <a href={usesData[selected].tagUrl} target='_blank' className={styles.qrExampleContainer}>
          <QRCode value={usesData[selected].tagUrl} />
        </a>
      </div>
      <div className={styles.accordionContainer}>
        <Typography variant='overline'>
          Ideal for:
        </Typography>
        <ul className={styles.accordionContainer}>
          {accordionItems}
        </ul>
      </div>
    </section>
  )
}
