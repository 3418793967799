import { Card } from '@mui/material'
import { tagIcons } from 'components/dashboard/tag-editor/core/AvailableTagIcons'
import { renderBasicFragment } from '../core/RenderBasicFragment'
import styles from './TagElement.module.scss'

export default function TagElement ({ elementData = {} }) {

  return (
    <Card classes={{ root: styles.elementCardRoot }}>
      <div className={styles.cardHeader}>
        {tagIcons[elementData.icon].icon}
        <span>{elementData.title}</span>
      </div>
      <div className={styles.cardContent}>
        {elementData.content.map((fragment, index) => renderBasicFragment(fragment, index))}
      </div>
    </Card>
  )
}