import { Box, Button, Card, TextField, Typography } from '@mui/material'
import PublicPageWrapper from 'components/wrappers/PublicPageWrapper'
import Head from 'next/head'
import Image from 'next/image'
import styles from './index.module.scss'
import introImage from '../public/images/index/index-intro-graphic.png'
import customerSatisfactionImage from '../public/images/index/index-customer-satisfaction.png'
import printOutImage from '../public/images/index/index-print-out.png'
import frameImage from '../public/images/index/index-qr-frame2.png'
import mytagsDashboardImage from '../public/images/index/mytags_dashboard.png'
import mytagsDashboardImageDetail from '../public/images/index/mytags_dashboard_detail.png'
import myTagsGifOne from '../public/images/index/demo-rich-tags.gif'
import QRCode from 'react-qr-code'
import ArrowSwooshLeft from 'components/svg-assets/ArrowSwooshLeft'
import BlobOne from 'components/svg-assets/BlobOne'
import Carousel from 'components/page-content/Carousel'
import { BeachAccessOutlined, DirectionsBusOutlined, FormatListBulletedOutlined, HelpOutlineOutlined, LocalDiningOutlined, LocalParkingOutlined, LocalTaxiOutlined, NearMeOutlined, RecordVoiceOverOutlined, WbSunnyOutlined, WifiOutlined } from '@mui/icons-material'
import Link from 'next/link'
import TagElement from 'components/tag-elements/render/TagElement'
import { tagElementLibrary } from 'components/dashboard/tag-editor/core/TagLibrary'
import UsesWithFrame from 'components/page-content/UsesWithFrame'

export default function Home() {
  return (
    <div>
      <Head>
        <title>MyTags | Provide information to your Visitors via QR Tags</title>
        <link rel="icon" href="/favicon.ico" />
        <script defer data-domain="mytags.info" src="https://plausible.io/js/plausible.js"></script>
      </Head>

      <PublicPageWrapper>

        <section className={`${styles.indexIntro} ${styles.containerWide}`}>
          <div className={styles.introContent}>
            <Typography variant='h1'>
              Enhance your visitor's experience with our easy-to-create <span style={{ color: '#F22D31' }}>QR Tags.</span>
            </Typography>
            <Typography variant='subtitle1'>
              Instantly share crucial information with your visitors - including descriptions, instructions, images and other.
            </Typography>
            <Typography>
              Whether you're a vacation rental owner, event organizer, or art curator, MyTags is the perfect tool to help you share information about your physical space. Try it today and see the difference it can make!
            </Typography>
            <Typography color='text.secondary' marginTop={1.5} fontStyle='italic'>
              You can create a Tag in just <strong>minutes</strong>.
            </Typography>
            <Link href='/create-account'>
              <Button
                variant='contained'
                size='large'
                color='primary'
                disableElevation
                sx={{ minWidth: '260px', mt: 4 }}
              >
                Sign up now!
              </Button>
            </Link>
          </div>
          <div className={styles.introDecoration}>
            <div className={styles.indexBlobMask}>
              <Image
                src={introImage}
                alt="Increase your Visitor satisfaction in a quick way!"
                layout='responsive'
                sizes="(min-width: 75em) 33vw,
                    (min-width: 48em) 50vw,
                    100vw"
              />
            </div>
          </div>
        </section>

        <UsesWithFrame />

        <section className={styles.indexTestAndSignUp}>
          <div className={`${styles.qrBelowFrameSection} ${styles.containerWide}`}>
            <Card
              classes={{ root: styles.signupCtaStripCard }}
            >
              <div className={`${styles.signupCtaStrip} ${styles.container}`}>
                <Typography variant='subtitle1' sx={{ mb: 0 }}>
                  Don't miss out! Sign up for <u>free</u> today and see the difference MyTags can make.
                </Typography>
                {/* <TextField
                  variant='standard'
                  placeholder='yourname@example.com'
                  fullWidth
                  size='large'
                  type='email'
                /> */}
                <Link href='/create-account' passHref>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    disableElevation
                    size='large'
                  >
                    Sign up
                  </Button>
                </Link>
              </div>
              <div className={styles.blobOne}>
                <BlobOne />
              </div>
              <div className={styles.blobTwo}>
                <BlobOne />
              </div>
              <div className={styles.blobThree}>
                <BlobOne />
              </div>
            </Card>
          </div>
        </section>

        <section className={styles.container}>
          <Typography variant='body2' color='text.hint' textAlign='center' sx={{ mt: 3, mb: 3 }}>
            <em>Signing up for MyTags is FREE and we don't require any credit card! If you outgrow our free plan you can upgrade to one of our paid plans listed <a href="/pricing">here</a>.</em>
          </Typography>
        </section>

        <section className={styles.container}>
          <Box
            textAlign='center'
            sx={{
              pt: 8,
              pb: 6,
            }}
          >
            <Typography variant='h1' as='h2' textTransform='none'>Provide information to visitors <span style={{ color: '#F22D31' }}>with ease</span>, and keep it up to date</Typography>
            <Typography variant='subtitle1'>You can use MyTags to provide visitors with updated information. Once you put the physical QR Codes up, there is no need to modify them - you can just <strong>update the content</strong> behind them!</Typography>
          </Box>
        </section>

        <section className={`${styles.containerWide} ${styles.tagElementsCarousel}`}>
          <Typography
            variant='h3'
            textAlign='center'
            color='primary'
            sx={{
              mb: 3,
              maxWidth: 600,
              ml: 'auto',
              mr: 'auto',
            }}
          >
            Use our widgets to easily provide all the important information to your visitors
          </Typography>
          <Carousel>
            <Card classes={{ root: styles.tagElementPreviewCard }}>
              <div className={styles.tagElementPreviewWrapper}>
                <TagElement elementData={tagElementLibrary.wifiInfo.defaultData} />
              </div>
            </Card>
            <Card classes={{ root: styles.tagElementPreviewCard }}>
              <div className={styles.tagElementPreviewWrapper}>
                <TagElement elementData={tagElementLibrary.apartmentContact.defaultData} />
              </div>
            </Card>
            <Card classes={{ root: styles.tagElementPreviewCard }}>
              <div className={styles.tagElementPreviewWrapper}>
                <TagElement elementData={tagElementLibrary.applianceInstructions.defaultData} />
              </div>
            </Card>
            <Card classes={{ root: styles.tagElementPreviewCard }}>
              <div className={styles.tagElementPreviewWrapper}>
                <TagElement elementData={tagElementLibrary.apartmentContact.defaultData} />
              </div>
            </Card>
            <Card classes={{ root: styles.tagElementPreviewCard }}>
              <div className={styles.tagElementPreviewWrapper}>
                <TagElement elementData={tagElementLibrary.wifiInfo.defaultData} />
              </div>
            </Card>
          </Carousel>
          <div className={styles.tagElementsCarouselCta}>
            <Link href='/create-account'>
              <Button
                variant='contained'
                color='primary'
                size='large'
                fullWidth
                disableElevation
                sx={{ maxWidth: '400px', mt: 4 }}
              >
                Sign up now and try it out for yourself
              </Button>
            </Link>
          </div>
        </section>

        <section className={`${styles.presentationContentBlock} ${styles.containerWide}`}>
          <div className={styles.presentationContent}>
            <Typography variant='overline'>
              Easy to use
            </Typography>
            <Typography variant='h2'>
              Create a Rich Tag using precreated templates, or simply upload an image or a document
            </Typography>
            <Typography variant='subtitle1'>
              MyTags is very flexible and will allow you to start small and add more content to your Tags later.
            </Typography>
            <Typography>
             If you already have a PDF document, or an Image - that you would like to easily share digitally with your visitors, you can do so in 5 seconds
             with MyTags. Later on, as you wish to add more content you can use our Rich Content Tags to create wonderful content to share with your visitors.
             All of this is hosted by us on an easily recognizable URL that is fast to load and optimized for mobile phone screens. All the information
             your visitors need just a QR code scan away!
            </Typography>
          </div>
          <div className={`${styles.presentationGraphic} ${styles.graphicBlobMask} ${styles.shunt}`}>
            <Image
              src={myTagsGifOne}
              alt='Scan the QR to see the Tag!'
              layout='responsive'
              sizes="(min-width: 75em) 33vw,
                  (min-width: 48em) 50vw,
                  100vw"
            />
          </div>
        </section>

        <div className={styles.presentationStripe}>
          <section className={`${styles.presentationContentBlock} ${styles.containerWide} ${styles.presentationAlternate}`}>
            <div className={styles.presentationContent}>
              <Typography variant='overline'>
                Increase customer satisfaction
              </Typography>
              <Typography variant='h2'>
                Delight your Visitors with easy information access and convenience
              </Typography>
              <Typography variant='subtitle1'>
                Where's the closest beach? What's the number of a reliable Taxi driver? How to use an appliance in the apartment? How to connect to the WiFi?
              </Typography>
              <Typography>
               With our custom templates you can add a QR code for connecting to the WiFi in just 2-3 clicks. Do you need a template with steps to easily describe how to use
               an appliance in your apartment? We got you covered! We will continuosly add new templates but if there isn't a template for your use case you can always
               make one yourself with our Rich Tag Content editor. It's super easy and you can even do it on your phone.
              </Typography>
            </div>
            <div className={`${styles.presentationGraphic} ${styles.graphicBlobMask} ${styles.shunt}`}>
              <Image
                src={customerSatisfactionImage}
                alt='Scan the QR code to see the Tag!'
                layout='responsive'
                sizes="(min-width: 75em) 33vw,
                    (min-width: 48em) 50vw,
                    100vw"
              />
            </div>
          </section>
        </div>

        <div className={styles.presentationStripe}>
          <section className={`${styles.tagElementsListStripe} ${styles.containerWide}`}>
            <Card
              classes={{ root: styles.tagElementsListStripeCard }}
            >
              <div className={`${styles.tagElementsListStripeContent} ${styles.container}`}>
                <div className={styles.tagElementsListStripeContentInner}>
                  <Typography variant='subtitle1' textAlign='center' sx={{ mb: 5 }}>
                    Some examples of information you can provide to your Visitors in your Tags:
                  </Typography>
                  <ul className={styles.elementsList}>
                    <li>
                      <RecordVoiceOverOutlined fontSize='large' color='primary' />
                      <span>Property contact</span>
                    </li>
                    <li>
                      <FormatListBulletedOutlined fontSize='large' color='primary' />
                      <span>House rules</span>
                    </li>
                    <li>
                      <WifiOutlined fontSize='large' color='primary' />
                      <span>Wifi information</span>
                    </li>
                    <li>
                      <LocalParkingOutlined fontSize='large' color='primary' />
                      <span>Parking</span>
                    </li>
                    <li>
                      <HelpOutlineOutlined fontSize='large' color='primary' />
                      <span>Instructions</span>
                    </li>
                    <li>
                      <LocalDiningOutlined fontSize='large' color='primary' />
                      <span>Meal schedule</span>
                    </li>
                    <li>
                      <DirectionsBusOutlined fontSize='large' color='primary' />
                      <span>Public transport</span>
                    </li>
                    <li>
                      <BeachAccessOutlined fontSize='large' color='primary' />
                      <span>Beaches</span>
                    </li>
                    <li>
                      <LocalTaxiOutlined fontSize='large' color='primary' />
                      <span>Local Taxi</span>
                    </li>
                    <li>
                      <NearMeOutlined fontSize='large' color='primary' />
                      <span>Custom directions</span>
                    </li>
                    <li>
                      <WbSunnyOutlined fontSize='large' color='primary' />
                      <span>Weather info</span>
                    </li>
                    <li>
                      <HelpOutlineOutlined fontSize='large' color='primary' />
                      <span>Custom content</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles.blobOne}>
                <BlobOne />
              </div>
              <div className={styles.blobTwo}>
                <BlobOne />
              </div>
              <div className={styles.blobThree}>
                <BlobOne />
              </div>
            </Card>
          </section>
        </div>

        <div className={styles.presentationStripe}>
          <section className={`${styles.presentationContentBlock} ${styles.containerWide}`}>
            <div className={styles.presentationContent}>
              <Typography variant='overline'>
                Offline Access
              </Typography>
              <Typography variant='h2'>
                Don't let internet connectivity issues spoil your visitor's experience. With our unique offline access feature, your guests can access all the information they need, anytime, anywhere.
              </Typography>
              <Typography variant='subtitle1'>
                Perhaps your visitors are going on a trip where they might not have internet access but want to have your custom tips with them.
              </Typography>
              <Typography>
                Offline use is built into MyTags! Each tag is downloadable as an Image or PDF document and does not require internet access anymore once downloaded.
                Wanna give your visitors tips on the local sights? Use our Rich Content Tags to tell a story, add tips and local spots that are a must see.
                Once they download the content as a PDF, they can wander around and enjoy the sights without worrying about roaming and internet charges.
              </Typography>
            </div>
            <div className={`${styles.presentationGraphic} ${styles.graphicBlobMask} ${styles.shunt}`}>
              <Image
                src={customerSatisfactionImage}
                alt='Scan the QR code to see the Tag!'
                layout='responsive'
                sizes="(min-width: 75em) 33vw,
                    (min-width: 48em) 50vw,
                    100vw"
              />
            </div>
          </section>
        </div>

        <section className={`${styles.presentationContentBlock} ${styles.containerWide} ${styles.presentationAlternate}`}>
          <div className={styles.presentationContent}>
            <Typography variant='overline'>
              Print out your Tag content
            </Typography>
            <Typography variant='h2'>
              Not all of your visitors tech-savvy? No problem! You can easily print out our visually pleasing and easy-to-understand tags.
            </Typography>
            <Typography variant='subtitle1'>
              Even though it’s a digital age, some of your visitors might not be tech-savvy.
            </Typography>
            <Typography>
              Don't worry, you can always print it out! We make sure to generate PDFs that stand out and are visually pleasing.
              Manage all content in MyTags and not only do you get a beautiful hosted website that is a QR code scan away but a well laid out PDF
              for free - without having to lift a finger. Just print it out and leave a copy on the kitchen table.
            </Typography>
          </div>
          <div className={`${styles.presentationGraphic} ${styles.graphicBlobMask} ${styles.shunt}`}>
            <Image
              src={printOutImage}
              alt='Scan the QR code to see the Tag!'
              layout='responsive'
              sizes="(min-width: 75em) 33vw,
                  (min-width: 48em) 50vw,
                  100vw"
            />
          </div>
        </section>

        <div className={styles.presentationStripe}>
          <section className={`${styles.presentationContentBlock} ${styles.containerWide}`}>
            <div className={styles.presentationContent}>
              <Typography variant='overline'>
                Multilingual support
              </Typography>
              <Typography variant='h2'>
                Create multilingual Tags, then have AI translate them to other supported languages (**in development**)
              </Typography>
              <Typography variant='subtitle1'>
                Sometimes your Visitors want to get information in their own language. Don’t put extra labour on yourself - leave it to us!
              </Typography>
              <Typography>
                You want to translate your content into multiple lanuages? No worries, you can just create a Tag per langauge you want to support.
                But ... if you're lazy you can leave it to our AI translation and it'll do the work for you. This feature is on the roadmap but still in development.
              </Typography>
            </div>
            <div className={styles.presentationGraphic}>
              <Image
                src={mytagsDashboardImage}
                alt='Scan the QR code to see the Tag!'
                layout='responsive'
                sizes="(min-width: 75em) 33vw,
                    (min-width: 48em) 50vw,
                    100vw"
              />
            </div>
          </section>
        </div>

        <div className={styles.presentationStripe}>
          <section className={`${styles.ctaBoxStripe} ${styles.containerWide}`}>
            <Card>
              <div className={styles.ctaBoxStripeCard}>
                <div></div>
                <div className={`${styles.ctaBoxStripeContent} ${styles.container}`}>
                  <div>
                    <Typography variant='h2' color='common.white'>
                      Revolutionize your business today and give your visitors an unforgettable experience.
                    </Typography>
                    <Typography variant='subtitle2' color='common.white'>
                      Answer all your Visitor's questions with just a few clicks.
                      Sign up now for free - no credit card required!
                    </Typography>
                  </div>
                  <Link href='/create-account' passHref>
                    <Button
                      variant='contained'
                      color='primary'
                      fullWidth
                      disableElevation
                      size='large'
                    >
                      Sign up now
                    </Button>
                  </Link>
                </div>
                <div className={styles.ctaBoxGraphic}>
                  <Image
                    src={customerSatisfactionImage}
                    alt='Scan the QR code to see the Tag!'
                    layout='responsive'
                    sizes="(min-width: 75em) 33vw,
                        (min-width: 48em) 50vw,
                        100vw"
                  />
                </div>
              </div>
            </Card>
          </section>
        </div>

      </PublicPageWrapper>
    </div>
  )
}

export async function getStaticProps() {

  return {
    props: {},
    revalidate: 10,
  }
}
