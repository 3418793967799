import { ArrowBackOutlined, CheckBoxOutlineBlankOutlined, CottageOutlined, HelpOutlineOutlined, RecordVoiceOverOutlined, WifiOutlined } from '@mui/icons-material'

export const tagElementLibraryList = [
  'wifiInfo',
  'apartmentContact',
  'empty',
  'applianceInstructions',
]

export const tagElementLibraryLists = {
  'root': [
    {
      id: 'empty',
      hasChildren: false,
    },
    {
      id: 'propertyRentals',
      hasChildren: true,
      targetListId: 'propertyRentals',
    },
  ],
  'propertyRentals': [
    {
      id: 'root',
      hasChildren: true,
      targetListId: 'root',
    },
    {
      id: 'apartmentContact',
      hasChildren: false,
    },
    {
      id: 'wifiInfo',
      hasChildren: false,
    },
    {
      id: 'applianceInstructions',
      hasChildren: false,
    },
  ]
}

export const tagElementLibrary = {
  // lists
  'root': {
    title: 'Back',
    description: 'Back to main choices',
    icon: <ArrowBackOutlined />,
  },
  'propertyRentals': {
    title: 'Property rental',
    description: 'Templates useful for property rentals',
    icon: <CottageOutlined />,
  },
  // elements
  'empty': {
    title: 'Empty Tag Element',
    description: 'No need for a template? Start from a fresh, empty Tag element.',
    icon: <CheckBoxOutlineBlankOutlined />,
    defaultData: {
      title: 'Your title here',
      icon: 'info',
      type: 'tagElement',
      content: [],
    }
  },
  'wifiInfo': {
    title: 'WiFi Information',
    description: 'Short description here. Sunt autem vel illum, qui blanditiis praesentium voluptatum deleniti atque.',
    icon: <WifiOutlined />,
    defaultData: {
      title: 'Wifi Information',
      icon: 'wifi',
      content: [
        {
          type: 'richText',
          values: {
            value: {
              "root": {
                "children": [
                  {
                    "children": [
                      {
                        "detail": 0,
                        "format": 0,
                        "mode": "normal",
                        "style": "",
                        "text": "Use the following WiFi info to connect:",
                        "type": "text",
                        "version": 1
                      }
                    ],
                    "direction": "ltr",
                    "format": "",
                    "indent": 0,
                    "type": "paragraph",
                    "version": 1
                  }
                ],
                "direction": "ltr",
                "format": "",
                "indent": 0,
                "type": "root",
                "version": 1
              }
            }
          }
        },
        {
          type: 'labeledValues',
          values: {
            items: [
              {
                label: 'SSID:',
                content: 'Apartment01b',
              },
              {
                label: 'Password:',
                content: `PnK193OGuest2022`,
              },
            ]
          }
        },
        {
          type: 'separator',
          values: {}
        },
        {
          type: 'wifiQrCode',
          values: {
            instructionContent: {
              "root": {
                "children": [
                  {
                    "children": [
                      {
                        "detail": 0,
                        "format": 0,
                        "mode": "normal",
                        "style": "",
                        "text": "Scan the QR code with your phone:",
                        "type": "text",
                        "version": 1
                      }
                    ],
                    "direction": "ltr",
                    "format": "",
                    "indent": 0,
                    "type": "paragraph",
                    "version": 1
                  }
                ],
                "direction": "ltr",
                "format": "",
                "indent": 0,
                "type": "root",
                "version": 1
              }
            },
            displayInstruction: true,
            ssid: 'MyNetworkName',
            ssidHidden: false,
            authType: 'WPA',
            password: 'MyPassword',
          }
        }
      ]
    }
  },
  'apartmentContact': {
    title: 'Apartment Contact',
    description: 'Short description here. Sunt autem vel illum, qui blanditiis praesentium voluptatum deleniti atque.',
    icon: <RecordVoiceOverOutlined />,
    defaultData: {
      title: 'Apartment Contact',
      icon: 'contact',
      type: 'tagElement',
      content: [
        {
          type: 'richText',
          values: {
            value: {
              "root": {
                "children": [
                  {
                    "children": [
                      {
                        "detail": 0,
                        "format": 0,
                        "mode": "normal",
                        "style": "",
                        "text": "In case you need any additional information, or encounter any problems related to your stay at our apartment, please feel free to contact us using the following information:",
                        "type": "text",
                        "version": 1
                      }
                    ],
                    "direction": "ltr",
                    "format": "",
                    "indent": 0,
                    "type": "paragraph",
                    "version": 1
                  }
                ],
                "direction": "ltr",
                "format": "",
                "indent": 0,
                "type": "root",
                "version": 1
              }
            },
          }
        },
        {
          type: 'labeledValues',
          values: {
            items: [
              {
                label: 'Name:',
                content: 'Ivanka Horvat',
              },
              {
                label: 'Mobile:',
                content: `+385 91 9191 919`,
              },
              {
                label: 'Apps:',
                content: 'WhatsApp, Viber',
              }
            ]
          }
        },
      ]
    }
  },
  'applianceInstructions': {
    title: 'Appliance instructions',
    description: 'Short description here. Sunt autem vel illum, qui blanditiis praesentium voluptatum deleniti atque.',
    icon: <HelpOutlineOutlined />,
    defaultData: {
      "title": "Dishwasher",
      "icon": "info",
      "type": "tagElement",
      "content": [
        {
          "type": "richText",
          "values": {
            "value": {
              "root": {
                "children": [
                  {
                    "children": [
                      {
                        "detail": 0,
                        "format": 0,
                        "mode": "normal",
                        "style": "",
                        "text": "You can find the dishwasher tablets in the cabinet just above the sink.",
                        "type": "text",
                        "version": 1
                      }
                    ],
                    "direction": "ltr",
                    "format": "",
                    "indent": 0,
                    "type": "paragraph",
                    "version": 1
                  },
                  {
                    "children": [
                      {
                        "detail": 0,
                        "format": 1,
                        "mode": "normal",
                        "style": "",
                        "text": "To use the dishwasher:",
                        "type": "text",
                        "version": 1
                      }
                    ],
                    "direction": "ltr",
                    "format": "",
                    "indent": 0,
                    "type": "paragraph",
                    "version": 1
                  }
                ],
                "direction": "ltr",
                "format": "",
                "indent": 0,
                "type": "root",
                "version": 1
              }
            }
          }
        },
        {
          "type": "instructionsSteps",
          "values": {
            "steps": [
              {
                "label": "1",
                "content": "Place the dirty dishes into the washer."
              },
              {
                "label": "2",
                "content": "Open the tablet  compartment in the door, place the tablet inside and close it."
              },
              {
                "label": "3",
                "content": "Press the “Auto” program button."
              },
              {
                "label": "4",
                "content": "Press the “Start” button."
              },
              {
                "label": "5",
                "content": "Close the door to start it."
              }
            ]
          }
        }
      ]
    }
  },
}