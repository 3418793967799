import { ArrowBackOutlined, ArrowForwardOutlined } from '@mui/icons-material';
import { Fab } from '@mui/material';
import React from 'react';
import { useSwipeable } from 'react-swipeable';
import styles from './Carousel.module.scss'

export default function Carousel ({ children, slidesToDisplay = 3 }) {

  const [shiftCount, setShiftCount] = React.useState(0);
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });
  const [carouselInnerWidth, setCarouselInnerWidth] = React.useState(0);

  const myRef = React.useRef();

  const refPassThrough = (el) => {
    handlers.ref(el);
    myRef.current = el;
  }

  React.useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        // restart carousel
        setShiftCount(0);
        setCarouselInnerWidth(myRef.current.offsetWidth);
      }
    
      // Add event listener
      window.addEventListener("resize", handleResize);
     
      // Call handler right away so state gets updated with initial window size
      handleResize();
    
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  const screenBreakpointMobile = 900;
  const screenBreakpointLarge = 1400;

  let adjustedSlidesToDisplay = slidesToDisplay;
  if (windowSize.width < screenBreakpointMobile) {
    adjustedSlidesToDisplay = 1;
  } else if (windowSize.width < screenBreakpointLarge) {
    adjustedSlidesToDisplay = 2;
  }

  const maxShiftCount = children.length - adjustedSlidesToDisplay;

  const handlers = useSwipeable({
    onSwipedLeft: () => { (shiftCount < maxShiftCount) && setShiftCount(shiftCount + 1) },
    onSwipedRight: () => { (shiftCount > 0) && setShiftCount(shiftCount - 1) },
  })

  const slidesRender = children.map((child, index) => {

    let scaleClass;

    if ((index >= shiftCount) && (index < (shiftCount + adjustedSlidesToDisplay))) scaleClass = styles.activeSlide;

    return (
      <div
        key={`slide-${index}`}
        className={`${styles.slideItem} ${scaleClass}`}
        style={{
          width: `${(100 / adjustedSlidesToDisplay).toFixed(1)}%`,
        }}
      >
        {child}
      </div>
    )
  })

  return (
    <div className={`${styles.carouselWrapper} ${styles.containerWide}`}>
      <div className={`${styles.carouselControls} ${styles.controlLeft}`}>
        <Fab
          color='primary'
          size={`${(windowSize.width < screenBreakpointMobile) ? 'small' : 'large'}`}
          disabled={shiftCount === 0}
          onClick={() => setShiftCount(shiftCount - 1)}
        >
          <ArrowBackOutlined />
        </Fab>
      </div>
      <div
        className={`${styles.carouselInnerWrapper} ${styles.container}`}
        {...handlers}
        ref={refPassThrough}
      >
        <div
          className={styles.carouselInner}
          style={{
            width: `${(100 * children.length / adjustedSlidesToDisplay).toFixed(1)}%`,
            transform: `translateX(-${(100 / children.length * shiftCount).toFixed(1)}%)`
          }}
        >
          {slidesRender}
        </div>
      </div>
      <div className={`${styles.carouselControls} ${styles.controlRight}`}>
        <Fab
          color='primary'
          size={`${(windowSize.width < screenBreakpointMobile) ? 'small' : 'large'}`}
          disabled={shiftCount === maxShiftCount}
          onClick={() => setShiftCount(shiftCount + 1)}
        >
          <ArrowForwardOutlined />
        </Fab>
      </div>
    </div>
  )
}